import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { exhaustMap, filter, switchMap, tap } from 'rxjs/operators';

import { CustomerService } from '../customers/customer.service';
import { FormatService } from './format.service';
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { Solution } from '../solutions/solution.model';
import { environment } from './../../environments/environment';
import { SolutionConfig } from '../solution-settings/solution-settings.model';
import { Customer } from '../customers/customer.model';
import { GlobalErrorHandler } from './global-error-handler.service';

export interface MIModule {
    name: string;
    value: string;
    description: string;
    selected: boolean;
    accessLevel: ModuleAccessLevel[];
}

export enum ModuleAccessLevel {
    Customer = 'customer',
    Solution = 'solution'
}

const MODULES: MIModule[] = [
    {
        name: 'Export map',
        value: 'export',
        description: 'Allows exporting tiles as images for print',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: 'Single Sign-On',
        value: 'singlesignon',
        description: 'Enables SAML or Azure Active Directory authentication',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: 'Logs',
        value: 'logs',
        description: 'Lets customer download logs',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: 'Zoom 22',
        value: 'z22',
        description: 'Enables zoom level 22',
        selected: true,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: 'Analytics Dashboard',
        value: 'dashboard',
        description: 'Allows the customer to view analytics data across their solutions',
        selected: false,
        accessLevel: [ModuleAccessLevel.Customer]
    },
    {
        name: 'Share via SMS',
        value: 'shareviasms',
        description: 'Enables sharing of the web app link via sms',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: 'Booking',
        value: 'booking',
        description: 'Enables booking service provider setup',
        selected: false,
        accessLevel: [ModuleAccessLevel.Customer]
    },
    {
        name: 'Cisco Webex',
        value: 'webex',
        description: 'Enables Cisco Webex integration setup',
        selected: false,
        accessLevel: [ModuleAccessLevel.Customer]
    },
    {
        name: 'Split & Combine',
        value: 'splitandcombine',
        description: 'Allows the customer to split and combine rooms',
        selected: false,
        accessLevel: [ModuleAccessLevel.Customer]
    },
    {
        name: '3D Walls',
        value: '3dwalls',
        description: 'Provides wall geometry data for locations',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: '3D Rooms',
        value: '3dextrusions',
        description: 'Provides room extrusion geometry data for locations',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: '2D Models',
        value: '2dmodels',
        description: 'Enables 2D Modules on solution.',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: '3D Models',
        value: '3dmodels',
        description: 'Enables 3D Modules on solution.',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: '2D Walls',
        value: 'floorplan',
        description: 'Enables 2D floor plans on solution.',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: 'Mapbox',
        value: 'mapbox',
        description: 'Enables Mapbox map.',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    },
    {
        name: 'Occupants',
        value: 'occupants',
        description: 'Enables occupants.',
        selected: false,
        accessLevel: [ModuleAccessLevel.Solution]
    }
];

@Injectable({ providedIn: 'root' })
export class SolutionService {
    private api = environment.APIEndpoint;
    private solutionUrl = this.api + 'api/solutions/';
    private currentSolution = new BehaviorSubject<Solution>(null);
    private solutionConfigSubject = new BehaviorSubject<any>(null);
    /* A subject of solutions that have not expired */
    private activeSolutions = new BehaviorSubject<Solution[]>([]);
    public solutionDeleted = new Subject<string>();
    public solutionUpdated = new Subject<Solution>();

    currentSolutionStatic: any;

    constructor(
        private http: HttpClient,
        private formatService: FormatService,
        private customerService: CustomerService,
        private notificationService: NotificationService
    ) {
        this.selectedSolution$
            .pipe(switchMap(solution => this.getSolutionConfig(solution.id)))
            .subscribe(solutionConfig => this.solutionConfigSubject.next(solutionConfig));
    }

    public getSolutions(): Observable<any> {
        return this.http.get(this.solutionUrl + '?details=true');
    }


    /**
     * Observable for the selcted solution.
     *
     * @readonly
     * @memberof SolutionService
     * @returns {Observable<Solution>}
     */
    public get selectedSolution$(): Observable<Solution> {
        return this.currentSolution.asObservable().pipe(filter(solution => !!solution));
    }

    /**
     * Observable for the solution config.
     *
     * @readonly
     * @memberof SolutionService
     * @returns {Observable<any>}
     */
    public get solutionConfig$(): Observable<SolutionConfig> {
        return this.solutionConfigSubject.asObservable()
            .pipe(filter(solutionConfig => !!solutionConfig));
    }

    /**
     * Get object for given solution id.
     *
     * @param {string} id - The solution id.
     * @returns {Observable<object>} - The solution object.
     * @memberof SolutionService
     */
    getSolution(id: string): Observable<Object> {
        return this.http.get(`${this.solutionUrl}details/${id}`);
    }

    /**
     * Update given solution config.
     *
     * @param {SolutionConfig} solutionConfig
     * @returns {Observable<SolutionConfig>}
     * @memberof SolutionService
     */
    updateSolutionConfig(solutionConfig: SolutionConfig): Observable<SolutionConfig> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put<SolutionConfig>(`${this.api}${solutionConfig.solutionId}/api/solutionconfig`, solutionConfig, { headers: headers })
            .pipe(tap(() => {
                this.solutionConfigSubject.next(solutionConfig);
            }));
    }

    /**
     * Get solution config for given solution id.
     *
     * @param {string} id
     * @returns {Observable<SolutionConfig>}
     */
    getSolutionConfig(id: string): Observable<SolutionConfig> {
        return this.http.get<SolutionConfig>(`${this.api}${id}/api/solutionconfig`);
    }

    /**
     * Save the current solution and customer ID to localstorage.
     * If the user is an admin, then fetch the customer.
     *
     * @param {*} solution
     * @param {boolean} isAdminUser
     * @memberof SolutionService
     */
    setCurrentSolution(solution: any, isAdminUser: boolean): void {
        if (solution) {
            this.currentSolutionStatic = solution;
            this.currentSolution.next(solution);
            localStorage.setItem('currentSolution', solution.id);
            localStorage.setItem('customerId', solution.customerId);

            GlobalErrorHandler.setTag('Solution.id', solution.id);
            GlobalErrorHandler.setTag('Solution.name', solution.name);


            if (isAdminUser) {
                this.customerService.getCustomer(solution.customerId)
                    .subscribe(
                        customer => this.customerService.setCurrentCustomer(customer),
                        error => {
                            this.notificationService.showError(error);
                            /* Use an empty customer object when there is no customer set for the solution.
                              This is only necessary for solutions in the dev environment */
                            this.customerService.setCurrentCustomer({ id: '', name: '' });
                        }
                    );
            }
        }
    }

    /**
     * Get currently active Solution as an observable.
     *
     * @returns {Observable<Solution>}
     */
    getCurrentSolution(): Observable<Solution> {
        return this.currentSolution.asObservable().pipe(filter(solution => !!solution));
    }

    /**
     * Get currently selected Solution.
     * This Solution is also saved in local storage.
     *
     * @returns {any}
     */
    public getStaticSolution(): any {
        if (this.currentSolutionStatic) {
            return this.currentSolutionStatic;
        } else {
            const solution: any = {};
            solution.id = localStorage['currentSolution'];
            return solution;
        }
    }

    /**
     * Create a new solution.
     *
     * @param {*} solution
     * @returns {Observable<any>}
     * @memberof SolutionService
     */
    public newSolution(solution): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(this.solutionUrl, solution, { headers })
            .pipe(
                exhaustMap((solutionId: string) => {
                    // create an appconfig mock
                    return this.http.post(this.api + solutionId + '/api/appconfig/', {
                        solutionId,
                        primaryURL: 'https://api.mapsindoors.com',
                        secondaryURL: 'https://api-us.mapsindoors.com',
                        appSettings: {},
                        menuInfo: {},
                        translations: {},
                        venueImages: {}
                    }, { headers });
                })
            );
    }

    /**
     * Update the given solution.
     *
     * @param {Solution} solution
     * @returns {Observable<void>}
     * @memberof SolutionService
     */
    public updateSolution(solution: Solution): Observable<void> {
        const lmDate = new Date(solution.lastModified);
        const lastModified = this.formatService.utcDate(lmDate);
        const requestOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'If-Modified-Since': lastModified });
        return this.http.put<void>(this.solutionUrl, solution, { headers: requestOptions })
            .pipe(tap(() => this.solutionUpdated.next({ ...solution })));
    }

    /**
     * Delete the given solution.
     *
     * @param solution
     * @returns
     */
    public deleteSolution(solution): Observable<any> {
        const lmDate = new Date(solution.lastModified);
        const lastModified = this.formatService.utcDate(lmDate);
        const requestOptions = new HttpHeaders({ 'If-Modified-Since': lastModified });
        return this.http.delete(this.solutionUrl + solution.id, { headers: requestOptions });
    }

    /**
     * Get the list of modules.
     *
     * @returns {MIModule[]}
     * @memberof SolutionService
     */
    public getModules(): MIModule[] {
        return MODULES;
    }

    /**
     * Get all data issues.
     *
     * @returns {Observable<any>}
     * @memberof SolutionService
     */
    public getDataIssues(): Observable<any> {
        return this.http.get<any>(`${this.api}api/dataissues`);
    }

    /**
     * Get a solution's data issues.
     *
     * @param {string} solutionId
     * @returns {Observable<any>}
     * @memberof SolutionService
     */
    public getDataIssuesOf(solutionId: string): Observable<any> {
        return this.http.get<any>(`${this.api}api/dataissues/details/${solutionId}`);
    }

    /**
     * Get all solutions that have not expired yet.
     *
     * @returns {any[]}
     * @memberof SolutionService
     */
    public getActiveSolutions(): Solution[] {
        return this.activeSolutions.value;
    }

    /**
     * Get all solutions that have not expired yet.
     *
     * @returns {Solution[]}
     * @memberof SolutionService
     */
    public getSyncableSolutions(): Solution[] {
        const solutionId = this.getStaticSolution().id;
        const currentCustomer = this.customerService.getCurrentCustomer(true);
        return currentCustomer
            ? this.getActiveSolutions()
                .filter(solution => {
                    return solution.customerId === (currentCustomer as Customer).id && solution.id !== solutionId;
                })
            : [];
    }

    /**
     * Publish active solutions.
     *
     * @param {Solution[]} solutions
     * @memberof SolutionService
     */
    public publishActiveSolutions(solutions: Solution[]): void {
        if (solutions) {
            this.activeSolutions.next(solutions);
        }
    }
}
