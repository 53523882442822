import { ErrorHandler, Injectable } from '@angular/core';
import { SentryErrorHandler, setTag } from '@sentry/angular';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler implements ErrorHandler {

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor() {
        super({ logErrors: environment?.sendLogsToSentry ?? false });
    }

    /**
     * Handle errors.
     *
     * @param {Error} error - The error.
     * @memberof GlobalErrorHandler
     */
    handleError(error: Error): void {
        super.handleError(error);
        // !IMPORTANT: Throw error to stop change detection from running again and creating an infinite loop.
        // This happens for errors in html templates or in ngOnChange licycle hook.
        if (error instanceof TypeError) {
            throw error;
        }
    }

    /**
     * Set key:value that will be sent as tags data with the event.
     * Can also be used to unset a tag, by passing undefined.
     *
     * @static
     * @param {string} key - Tag key.
     * @param {string} value - Tag value.
     * @returns {void}
     */
    static setTag(key: string, value: string): void {
        setTag(key, value);
    }
}