import { Component, ElementRef, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { createDropdownItemElement } from '../../mi-dropdown/mi-dropdown';
import { Subscription } from 'rxjs';
import { OccupantTemplateService } from '../../../services/OccupantServices/occupant-template.service';
import { SimplifiedOccupantTemplate } from '../../../services/OccupantServices/simplifiedOccupantTemplate.model';

@Component({
    selector: 'occupant-template-dropdown',
    templateUrl: './occupant-template-dropdown.component.html',
    styleUrls: ['./occupant-template-dropdown.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OccupantTemplateDropdownComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => OccupantTemplateDropdownComponent),
        multi: true,
    }]
})
export class OccupantTemplateDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @ViewChild('occupantTemplateDropdown', { static: true }) occupantTemplateDropdownElement: ElementRef<HTMLMiDropdownElement>;

    constructor(
        private occupantTemplateService: OccupantTemplateService,
    ) { }

    public onChange: (occupantTemplate: string) => void = () => { };
    public onTouch: () => void = () => { };

    public isDropDownSelectionValid: boolean = true;

    private subscription: Subscription = new Subscription();
    private occupantTemplateId: string;
    private occupantTemplates: SimplifiedOccupantTemplate[];
    private dropdownItems: HTMLMiDropdownItemElement[];
    private selectedDropdownItem: HTMLMiDropdownItemElement;

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.subscription.add(
            this.occupantTemplateService.simplifiedOccupantTemplates$.subscribe((occupantTemplates) => {
                this.occupantTemplates = occupantTemplates.sort((a, b) => a.name.localeCompare(b.name));
                const defaultDropdownItem = createDropdownItemElement({ label: 'Select Template', value: null });
                this.dropdownItems = [].concat(defaultDropdownItem, this.createDropdownItems(this.occupantTemplates));

                if (this.occupantTemplateId) {
                    this.selectDropdownItem();
                }

                this.occupantTemplateDropdownElement.nativeElement.items = this.dropdownItems;
            })
        );
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Creates the dropdown items.
     *
     * @param {SimplifiedOccupantTemplate[]} occupantTemplates
     * @returns {HTMLMiDropdownItemElement[]}
     */
    private createDropdownItems(occupantTemplates: SimplifiedOccupantTemplate[]): HTMLMiDropdownItemElement[] {
        const dropdownItems = [];

        for (const occupantTemplate of occupantTemplates) {
            dropdownItems.push(createDropdownItemElement({ label: occupantTemplate.name, value: occupantTemplate.id }));
        }

        return dropdownItems;
    }

    /**
     * Sets the selected dropdown item.
     */
    private selectDropdownItem(): void {
        if (this.dropdownItems?.length > 0) {
            this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem.value === this.occupantTemplateId);

            if (this.selectedDropdownItem) {
                this.selectedDropdownItem.selected = true;
                this.onChange(this.occupantTemplateId);
            }
        }
    }

    /**
     * On occupant template dropdown change.
     *
     * @param {CustomEvent} event
     */
    public onOccupantTemplateDropdownChange(event: CustomEvent): void {
        this.occupantTemplateId = event.detail[0].value;
        this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem.value === this.occupantTemplateId);
        this.onChange(this.occupantTemplateId);
    }

    /**
     * WriteValue.
     *
     * @param {string} value
     */
    writeValue(value: string): void {
        this.occupantTemplateId = value;
        if (this.occupantTemplates?.length > 0) {
            this.selectDropdownItem();
        }
    }

    /**
     * RegisterOnChange.
     *
     * @param {(occupantTemplateId: string) => void} fn
     */
    registerOnChange(fn: (occupantTemplateId: string) => void): void {
        this.onChange = fn;
    }

    /**
     * RegisterOnTouched.
     *
     * @param {() => void} fn
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Validate.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        this.isDropDownSelectionValid = !!this.selectedDropdownItem && this.selectedDropdownItem?.value !== null;
        return this.isDropDownSelectionValid ? {} : { invalid: true };
    }
}