const version = require('../../package.json')?.version;

export const environment = {
    production: true,
    name: 'staging',
    clientId: 'cms',
    sendLogsToSentry: true,
    googleAnalytics: '',
    version: version,
    startPage: '/map',
    AnalyticsEndpoint: 'https://analytics-staging.mapsindoors.com',
    APIEndpoint: 'https://v2.mapsindoors.com/',
    MediaEndpoint: 'https://media.mapsindoors.com/',
    authApi: 'https://auth.mapsindoors.com',
    clientApi: 'https://api.mapsindoors.com/',
    iconsBaseUrl: 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/',
    labelsBaseUrl: 'https://app.mapsindoors.com/mapsindoors/cms/assets/graphic-labels/'
};
